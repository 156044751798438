import { template as template_43966423fa8648fea06df0f868165a01 } from "@ember/template-compiler";
const FKLabel = template_43966423fa8648fea06df0f868165a01(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
