import { template as template_75692d66d96c45428c5f17e959b27e98 } from "@ember/template-compiler";
const WelcomeHeader = template_75692d66d96c45428c5f17e959b27e98(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
