import { template as template_c70351d4b2394f3c8e0158a954d813d1 } from "@ember/template-compiler";
const FKText = template_c70351d4b2394f3c8e0158a954d813d1(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
