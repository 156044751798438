import { template as template_6b3b471dd64e4a709bd7394477e0c3c6 } from "@ember/template-compiler";
const SidebarSectionMessage = template_6b3b471dd64e4a709bd7394477e0c3c6(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
